import isEmpty from 'lodash/isEmpty';

const DEFAULT_LOCALE = 'pt';

class Module {
  constructor(nuxt) {
    this.$auth = nuxt.$auth;
    this.$segmentIdentifyUser = nuxt.$segmentIdentifyUser;
    this.$i18n = nuxt.$i18n;
    this.locale = DEFAULT_LOCALE;
    this.$localePath = nuxt.$localePath;
    this.$router = nuxt.$router;
  }

  get route() {
    return this.$router.currentRoute.value;
  }

  async handle({ redirectTo, withQuery, withRedirect = true }) {
    this.setWorkspaceSlugAsTokenPrefix();
    await this.$segmentIdentifyUser();
    this.defineLangFromUser();

    if (withRedirect) {
      return this.$router.push(this.redirectRoute({ redirectTo, withQuery }));
    }

    return null;
  }

  redirectRoute({ redirectTo, withQuery }) {
    const redirectRoute = redirectTo
      || this.route.query.redirectTo
      || window.localStorage?.getItem('redirectTo')
      || this.buildRedirectToPath({ withQuery });

    window.localStorage?.removeItem('redirectTo');

    return redirectRoute;
  }

  defineLangFromUser() {
    const { language: defaultLang = DEFAULT_LOCALE } = this.$auth.user?.workspace || {};

    this.locale = defaultLang;
    this.$i18n.setLocale(defaultLang);
  }

  setWorkspaceSlugAsTokenPrefix() {
    const currentPrefix = this.$auth.strategy.token.scheme.options.token.prefix;

    // If the token prefix is default, we need to change it to the workspace slug
    // because now we have a workspace slug to use
    if (currentPrefix === '_token.') {
      const currentToken = this.$auth.strategy.token.get();
      const workspaceSlug = this.$auth.user.workspace.slug;

      this.$auth.strategy.token.reset();
      this.$auth.strategy.token.scheme.options.token.prefix = `${workspaceSlug}_token.`;
      this.$auth.strategy.token.set(currentToken);
    }
  }

  buildRedirectToPath({ withQuery }) {
    const localePathParams = {
      name: 't-tenantSlug-m',
      params: {
        tenantSlug: this.$auth.user?.workspace?.slug || this.route.params.tenantSlug,
      },
    };

    if (!isEmpty(this.route.query) && withQuery) {
      localePathParams.query = this.route.query;
    }

    return this.$localePath(localePathParams, this.locale);
  }
}

export default Module;
