import type { InfoComponentType } from '~/types';
import type { MainContent } from '~/types/pageStateComponent';

export function updateInfoComponentById(mainContent, id, newInfoComponent) {
  return mainContent.map((infoComponent) => {
    if (infoComponent.id === id) {
      return newInfoComponent;
    }

    if (Array.isArray(infoComponent.items)) {
      return {
        ...infoComponent,
        items: updateInfoComponentById(infoComponent.items, id, newInfoComponent),
      };
    }

    return infoComponent;
  });
}

export function ungroupMainContentInfoComponentById(mainContent: MainContent, groupInfoComponentId) {
  const ungroupedMainContent = [];

  mainContent.forEach((item) => {
    if (item.id === groupInfoComponentId) {
      ungroupedMainContent.push(...item.items);
    } else if (Array.isArray(item.items)) {
      item.items = ungroupMainContentInfoComponentById(item.items, groupInfoComponentId);
      ungroupedMainContent.push(item);
    } else {
      ungroupedMainContent.push(item);
    }
  });

  return ungroupedMainContent;
}

export function removeInfoComponentById(mainContent, infoComponentId) {
  return mainContent.filter((item) => {
    if (item.id === infoComponentId) {
      return false;
    }

    if (Array.isArray(item.items)) {
      item.items = removeInfoComponentById(item.items, infoComponentId);
    }

    return true;
  });
}

export function findInfoComponentById(mainContent, infoComponentId) {
  let infoComponent;

  mainContent.forEach((item) => {
    if (infoComponent) return;

    if (item.id === infoComponentId) {
      infoComponent = item;
    } else if (Array.isArray(item.items)) {
      infoComponent = findInfoComponentById(item.items, infoComponentId);
    }
  });

  return infoComponent;
}

export function findInfoComponentsByType(mainContent: MainContent, type: InfoComponentType) {
  const infoComponents = [];

  mainContent.forEach((item) => {
    if (item.type === type) {
      infoComponents.push(item);
    }

    if (Array.isArray(item.items)) {
      infoComponents.push(...findInfoComponentsByType(item.items, type));
    }
  });

  return infoComponents;
}

// Sometimes the associatedView is not available yet and this method will return null
// Example: When we are transforming a label link to a minicard, backend will create a subview for us when the update is finished
// We must be prepared to handle this case always
export function findAssociatedView(infoComponentId, mainContent, view) {
  if (mainContent.some(item => item.id === infoComponentId)) {
    return view;
  }

  let associatedView = null;

  mainContent.forEach((item) => {
    if (associatedView) return;

    if (Array.isArray(item.items)) {
      if (item.type === 'group') {
        associatedView = findAssociatedView(infoComponentId, item.items, view);
      } else {
        const subview = view.subviews.find(subview => subview.metadata.from_field_id === item.field_id);
        if (subview) associatedView = findAssociatedView(infoComponentId, item.items, subview);
      }
    }
  });

  return associatedView;
}
