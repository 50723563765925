import LoginCallback from '../callback';

export class Module {
  constructor(nuxt) {
    this.$api = nuxt.$api;
    this.$auth = nuxt.$auth;
    this.$recaptcha = nuxt.$recaptcha;
    this.$router = nuxt.$router;
    this.callbackHandler = new LoginCallback(nuxt);
  }

  get route() {
    return this.$router.currentRoute.value;
  }

  async login({ token, withRedirect = true }) {
    await this.$auth.loginWith('local', {
      url: 'authentication/magic_link',
      method: 'post',
      body: { token },
    });

    delete this.route.query.token;

    return this.callbackHandler.handle({ withQuery: true, withRedirect });
  }

  async send({ email }) {
    await this.$recaptcha.fetchToken(async token => this.$api
      .post('authentication/magic_link/send', {
        body: {
          email,
          tenant_slug: this.route.params.tenantSlug,
          recaptcha_token: token,
        },
      }));
  }
}

export function SetupMagicLink(nuxt) {
  nuxt.$auth.magicLinkModule = new Module(nuxt);
}
