import { v4 as uuidv4 } from 'uuid';

export default class FilterBarInfoComponentOptionBuilder {
  filterBarInfoComponent: any;

  static build(infoComponent) {
    return new FilterBarInfoComponentOptionBuilder(infoComponent).build();
  }

  constructor(infoComponent) {
    this.filterBarInfoComponent = infoComponent;
  }

  build() {
    const filterBarInfoComponentId = this.filterBarInfoComponent.id || uuidv4();

    const filterBarInfoComponentOption = {
      ...this.filterBarInfoComponent,
      id: filterBarInfoComponentId,
      title: this.filterBarInfoComponent.label,
      titleTypography: 'text-body-2',
      icon: 'fa-regular fa-filter',
    };

    return filterBarInfoComponentOption;
  }
}
