import type { GetterTree, MutationTree } from 'vuex';
import { isEqual } from 'lodash';
import { VIEW_TYPES } from '~/assets/javascript/constants';
import { createDeconstructedPromise, extractViewRecords } from '~/assets/javascript/utils';

export const state = () => ({
  view: {},
  subviews: [],
  viewsChangedAtMapping: {},
  filterBarFilters: {},
});

type State = ReturnType<typeof state>;

export const getters = <GetterTree<State, any>>{
  currentViewChangedAt: ({ viewsChangedAtMapping, view }) => viewsChangedAtMapping[view.id],
  // View configs
  layoutOptions: ({ view }) => VIEW_TYPES[view.page_type]?.builderOptions || {},
  componentsOptions: ({ view }) => (VIEW_TYPES[view.page_type]?.components || []).reduce((acc, component) => {
    acc[component.key] = component;
    return acc;
  }, {}),
  // Permissions
  canEdit: ({ view }) => Boolean(view.permit_record_update),
  canCreate: ({ view }) => Boolean(view.permit_record_insert),
  canDelete: ({ view }) => Boolean(view.permit_record_delete),
  redirectOptions: ({ view }) => view.fields_redirect_options,
  viewAppData: ({ view: { metadata = {}, page_type: pageType, page_name: pageName } }) => ({
    apps: metadata.apps,
    appCategories: metadata.app_categories,
    layout: pageType,
    name: pageName,
  }),
  findSubview: ({ subviews }) => (viewId, fieldId) => subviews.find(
    subview => subview.metadata.parent_view_id === viewId
      && subview.metadata.from_field_id === fieldId,
  ),
  isArchived: ({ view }) => Boolean(view.archived_at),
  pagePublished: ({ view }) => view.page_published,
};

const loadSubviews = (state, view) => {
  (view.subviews || []).forEach((subview) => {
    state.subviews.push(subview);
    loadSubviews(state, subview);
  });
};

export const mutations = <MutationTree<State>>{
  clearView(state) {
    state.view = {};
    state.subviews = [];
    state.viewsChangedAtMapping = {};
  },
  setView(state, payload) {
    state.view = payload;
    state.subviews = [];
    state.viewsChangedAtMapping = {};

    loadSubviews(state, payload);
  },
  refreshTableView(state, view) {
    Object.keys(view).forEach((key) => {
      if (isEqual(state.view[key], view[key])) return;
      state.view[key] = view[key];
    });
  },
  setViewChangedAt(state, { viewId, changedAt }) {
    state.viewsChangedAtMapping = {
      ...state.viewsChangedAtMapping,
      [viewId]: changedAt,
    };
  },
  setFilterBarFilter(state, { infoComponentId, filters }) {
    if (!filters) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state.filterBarFilters[infoComponentId];
    } else {
      state.filterBarFilters[infoComponentId] = filters;
    }
  },

};

export const actions = {
  getViewData({ state: { filterBarFilters } }, { viewId, recordId, tenantSlug, isPublic = false }) {
    const params = {};

    if (recordId) {
      params.record_id = recordId;
    }

    if (!_isEmpty(filterBarFilters)) {
      params.filters = filterBarFilters;
    }

    const path = isPublic ? `/public/workspaces/${tenantSlug}/views/${viewId}` : `/views/${viewId}`;

    return useNuxtApp().$api.$get(path, { params });
  },
  applyViewData({ commit, state }, { data, viewId, refresh = false }) {
    const { records, view } = extractViewRecords(data);

    // reset records if view changed
    if (!refresh) commit('records/reset', null, { root: true });
    // reset filters only if the new view is different from the current one
    // when opening a record from an index of records the view will be loaded again
    // and the filters should not be reset, because it is loaded asynchronously and can be overridden
    if (viewId !== state.view.id) commit('records/resetViewFiltersAndRequiredFields', null, { root: true });

    commit('viewFields/setFields', view.fields, { root: true });
    commit('records/loadRecordsByView', { ...view, records }, { root: true });

    if (refresh) {
      const mutationName = view.page_type === 'Table' ? 'refreshTableView' : 'setView';

      if (window) window.dispatchEvent(new Event('global:beforeViewRefresh'));
      commit(mutationName, view);
      if (window) window.dispatchEvent(new Event('global:afterViewRefresh'));
    } else {
      commit('setView', view);
    }

    return view;
  },
  async loadView({ dispatch }, { viewId, recordId, tenantSlug = null, refresh = false, isPublic = false }) {
    const {
      promise,
      reject,
      resolve,
    } = createDeconstructedPromise();

    const loadMethod = () => dispatch('getViewData', { viewId, recordId, tenantSlug, isPublic });
    const onLoad = data => dispatch('applyViewData', { data, viewId, refresh });
    const onLoadError = error => reject(error);

    useNuxtApp().$asyncDataManager.load({
      loadMethod,
      onLoad,
      onLoadError,
      loadId: `view__${viewId}`,
    }).then(resolve);

    return promise;
  },
  fakeLoadBuilderView({ commit }, view) {
    return Promise.all([
      commit('setView', view),
      commit('viewFields/setFields', view.fields, { root: true }),
    ]);
  },
  markViewAsChanged({ commit }, viewId) {
    commit('setViewChangedAt', { viewId, changedAt: Date.now() });
  },
  setFilterAndApply({ state, commit, dispatch }, { infoComponentId, filters }) {
    commit('setFilterBarFilter', { infoComponentId, filters });
    const { recordId } = useRoute().params;

    dispatch('loadView', {
      viewId: state.view.id,
      refresh: true,
      recordId,
    });
  },
};

export const namespaced = true;
