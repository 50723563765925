
type Options = {
  index: (
    viewId: string,
    infoComponentId: string,
    query: {
      record_id: string;
    }
  ) => Promise<any>;
};

export const options = ($http: HttpApi): Options => ({
  index: (viewId, infoComponentId, query) => $http.$get(
    `/views/${viewId}/filter_bar/${infoComponentId}/options`,
    { query },
  ),
});
