<template>
  <v-chip
    ref="chip"
    class="deck-chip"
    :class="classes"
    :size="computedSize"
    :href="href"
    :to="to"
    :ripple="clickable"
    :tag="shouldBeClickable ? 'button' : 'span'"
    :type="shouldBeClickable ? 'button' : null"
    :aria-label="icon ? text : ariaLabel"
    v-bind="$attrs"
  >
    <template
      v-if="iconPrepend"
      #prepend
    >
      <deck-icon
        start
        :name="iconPrepend"
        :kind="iconKind"
        :size="computedSize"
        fixed-width
        left
      />
    </template>

    <template
      #default
    >
      <deck-icon
        v-if="icon"
        :name="icon"
        :kind="iconKind"
        :size="computedSize"
        fixed-width
      />

      <template v-else>
        <template v-if="number !== undefined && number !== null">
          {{ number }}
        </template>

        <template v-else-if="text">
          <!-- Trimmer tooltip won't render if there's a custom tooltip slot -->
          <deck-trimmer
            :disable-tooltip="shouldRenderTooltip"
            :text="text"
          />
        </template>

        <!-- @slot Default slot to render custom content in the chip -->
        <slot v-else />
      </template>

      <deck-tooltip
        v-if="shouldRenderTooltip"
        ref="tooltip"
        v-bind="computedTooltipProps"
        class="deck-chip__tooltip"
      >
        <!-- @slot tooltip Slot to render custom content in the inner chip tooltip -->
        <template
          v-if="$slots.tooltip"
          #content
        >
          <slot name="tooltip" />
        </template>
      </deck-tooltip>
    </template>

    <template
      v-if="computedIconAppend || $slots.append"
      #append
    >
      <deck-icon
        v-if="computedIconAppend"
        :name="computedIconAppend"
        :kind="iconKind"
        :size="computedSize"
        fixed-width
        right
      />
      <!-- @slot Optional slot to render custom content at the end of the chip, eg: a clear button -->
      <slot name="append" />
    </template>
  </v-chip>
</template>

<script lang="ts">
import DeckTrimmer from '~/deck/trimmer/index.vue';
import { camelCase } from 'lodash';
import { PRIMARY_COLORS } from '~/assets/javascript/constants';

/**
 * ### ⚠️ Use this instead of `v-chip` for all chips on Zazos. Props can still be inherited.
 * A chip component that is styled to adhere to the Deck visual guidelines.
 * It must be always pill-shaped.
 * Extends and adapts the Vuetify v-chip component.
 * @see https://v2.vuetifyjs.com/en/api/v-chip/
 */
export default {
  name: 'DeckChip',
  components: {
    DeckTrimmer,
  },
  props: {
    /**
     * The color of the chip. Choose from preset or any valid CSS color.
     * @type {'plain' | 'blue' | 'cyan' | 'teal' | 'green' | 'yellow' | 'orange' | 'red' | 'pink' | 'deepPurple' | 'grey' | string}
     * @default 'plain'
     */
    color: {
      type: String,
      default: 'plain',
    },
    /**
     * The text rendered inside the chip. Required if `icon` or `number` are set for accessibility.
     * If you wish not to render the tooltip, disable it through `tooltipProps`.
     * @type {string | number}
     * @default undefined
     */
    text: {
      type: [String, Number],
      default: undefined,
      validator(value) {
        if (value === undefined && (this.icon !== undefined || this.number !== undefined)) {
          return false;
        }

        return true;
      },
    },
    /**
     * The number rendered inside the chip. Will apply a hard-coded width to the
     * chip, just like when icon-only. `text` will be rendered inside the
     * tooltip instead.
     * @type {number}
     * @default undefined
     */
    number: {
      type: Number,
      default: undefined,
    },
    /**
     * The aria-label for the chip. Won't be populated if `text` is visibily
     * rendered, as it would be redundant. Will be populated with the `text`
     * value if `icon` is set. Will force populated value if explicitly set.
     * @type {string}
     * @default undefined
     */
    ariaLabel: {
      type: String,
      default: undefined,
    },
    /**
     * The size of the chip.
     * @type {'x-small' | 'small' | 'default' | string}
     * @default 'medium'
     */
    size: {
      type: String,
      default: 'default',
    },
    /**
     * A static URL to navigate to when the button is clicked. Use `to` instead if you want to navigate to a route.
     * @type {string}
     * @default undefined
     * @see https://v2.vuetifyjs.com/en/api/v-chip/#props-href
     */
    href: {
      type: String,
      default: undefined,
    },
    /**
     * The route object or string to navigate to when the button is clicked. Use `href` instead if you want to navigate to a static URL.
     * @type {Object | string}
     * @default undefined
     * @see https://v2.vuetifyjs.com/en/api/v-chip/#props-to
     */
    to: {
      type: [Object, String],
      default: undefined,
    },
    /**
     * The name of the icon from FontAwesome to exclusively display on the button.
     * This will force `text` into an `aria-label`. Won't render `text` or `number` inside the chip.
     * Use `iconPrepend` and `iconAppend` instead if you want to display text alongside the icon.
     * @type {string}
     * @default undefined
     * @example 'pencil'
     * @see https://fontawesome.com/search
     */
    icon: {
      type: String,
      default: undefined,
    },

    /**
     * The name of the icon from FontAwesome to display on the left side of the button text.
     * @type {string}
     * @default undefined
     * @example 'pencil'
     * @see https://fontawesome.com/search
     */
    iconPrepend: {
      type: String,
      default: undefined,
    },

    /**
     * The name of the icon from FontAwesome to display on the right side of the button text.
     * @type {string}
     * @default undefined
     * @example 'pencil'
     * @see https://fontawesome.com/search
     */
    iconAppend: {
      type: String,
      default: undefined,
    },

    /**
     * Override the style of FontAwesome icon to use.
     * @type {'regular' | 'solid' | string}
     * @default 'regular'
     */
    iconKind: {
      type: String,
      default: undefined, // undefined to inherit deck-icon defaults
    },

    /**
     * Use this in order to give a clickable style to the chip.
     * @type {boolean}
     * @default false
     */
    clickable: {
      type: Boolean,
      default: false,
    },

    /**
     * Additional props to be passed to the `deck-tooltip` component if `icon`||`number` and `text` are set.
     * @type {Object}
     * @default {}
     */
    tooltipProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      zazos: useZazos(),
    };
  },
  data() {
    return {
      chipRef: null,
    };
  },
  computed: {
    classes() {
      return {
        'deck-chip--clickable': this.shouldBeClickable,
        'deck-chip--small': this.size === 'small',
        'deck-chip--x-small': this.size === 'x-small',
        'deck-chip--icon': this.icon,
        'deck-chip--number': this.number !== undefined && this.number !== null,
        [this.colorBackground]: true,
      };
    },
    computedSize() {
      return this.size === 'x-small' ? 'small' : this.size;
    },
    hasClickListener() {
      return Boolean(this.$attrs.onClick);
    },
    hasLink() {
      return Boolean(this.href || this.to);
    },
    computedIconAppend() {
      return this.hasLink && !this.iconAppend ? 'external-link' : this.iconAppend;
    },
    shouldBeClickable() {
      return this.clickable || this.hasLink || this.hasClickListener;
    },
    availableColors() {
      return PRIMARY_COLORS;
    },
    plainColor() {
      if (this.zazos.darkMode.value) {
        return this.shouldBeClickable ? 'bg-grey-lighten-3' : 'bg-grey-darken-3';
      }

      return this.shouldBeClickable ? 'bg-grey-darken-3' : 'bg-grey-lighten-3';
    },
    tonality() {
      if (this.zazos.darkMode.value) {
        return this.shouldBeClickable ? 'lighten-4' : 'darken-1';
      }

      return this.shouldBeClickable ? 'darken-1' : 'lighten-4';
    },
    colorBackground() {
      if (this.color === 'plain') return this.plainColor;
      if (this.color === 'yellow') return this.shouldBeClickable ? 'bg-yellow-darken-4' : `bg-yellow-${this.tonality}`;
      if (this.color.includes('#')) return this.color;

      return this.availableColors.includes(camelCase(this.color))
        ? `bg-${this.color}-${this.tonality}`
        : `bg-${this.color}`;
    },
    shouldRenderTooltip() {
      const hasTooltipSlot = Boolean(this.$slots.tooltip);
      const hasTooltipPropsText = Boolean(this.tooltipProps.text);
      const hasIconOrNumber = this.icon !== undefined || this.number !== undefined;
      const hasText = Boolean(this.text);

      return hasTooltipSlot || hasTooltipPropsText || (hasIconOrNumber && hasText);
    },
    computedTooltipProps() {
      return {
        ...this.tooltipProps,
        text: this.tooltipProps.text || this.text, // Auto populate tooltip with `text` prop when chip is icon only or number only
        activator: this.chipRef,
      };
    },
  },
  mounted() {
    nextTick().then(() => {
      this.chipRef = this.$refs?.chip?.$el;
    });
  },
};
</script>

<style lang="scss">
.deck-chip {
  justify-content: center;
  padding: var(--z-s2) var(--z-s3);

  &:not(.deck-chip--clickable) {
    cursor: auto;
    box-shadow: none !important;

    &::before {
      opacity: 0 !important;
    }
  }

  .v-chip__content {
    justify-content: center;
  }

  // Remove underlay behavior that follow chip color with opacity
  &.v-chip--variant-tonal .v-chip__underlay {
    background: transparent !important;
  }
}

.deck-chip--small, .deck-chip--x-small {
  font-weight: 600 !important;
}

.deck-chip--x-small {
  height: 20px !important;
}

.deck-chip--clickable {
  cursor: pointer;
  user-select: none;
  font-weight: 600;

  &:hover::before {
    opacity: 0.2 !important;
  }
}

.deck-chip--icon, .deck-chip--number {
  width: 44px;

  &.deck-chip--small {
    width: 32px;
  }

  &.deck-chip--x-small {
    width: 28px;
  }
}

</style>
