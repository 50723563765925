
import { pick } from 'lodash';
import { HELPER_TEXT_ATTRIBUTES, LABEL_ATTRIBUTES } from '~/assets/javascript/constants';
import InfoComponentOptionBuilder from '.';

export default class GroupInfoComponentFromOptionBuilder {
  static build(infoComponentOption) {
    return new GroupInfoComponentFromOptionBuilder(infoComponentOption).build();
  }

  constructor(infoComponentOption) {
    this.groupInfoComponentOption = infoComponentOption;
  }

  build() {
    return {
      ...pick(
        this.groupInfoComponentOption,
        [
          'id',
          'columns',
          'type',
          ...HELPER_TEXT_ATTRIBUTES,
          ...LABEL_ATTRIBUTES,
        ],
      ),
      items: this.groupInfoComponentOption.items.map(
        infoComponentOption => InfoComponentOptionBuilder.build(infoComponentOption),
      ),
    };
  }
}
