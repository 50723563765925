<template>
  <v-card
    class="deck-exodia"
    :to="to"
    :class="exodiaClasses"
    role="group"
    :aria-label="title"
  >
    <DeckExodiaToolbar
      v-if="showToolbar"
      :size="size"
      :show-profile-image="showProfileImage"
      :show-previous-btn="showPreviousBtn"
      :show-next-btn="showNextBtn"
      :show-expand-btn="showExpandBtn"
      :show-close-btn="showCloseBtn"
      :navigation-direction="navigationDirection"
      @previous-btn-click="$emit('previousBtnClick')"
      @next-btn-click="$emit('nextBtnClick')"
      @expand-btn-click="$emit('expandBtnClick')"
      @close-btn-click="$emit('closeBtnClick')"
    >
      <template
        v-if="$slots['toolbar-items']"
        #toolbar-items="props"
      >
        <slot
          name="toolbar-items"
          v-bind="props"
        />
      </template>
    </DeckExodiaToolbar>

    <DeckExodiaHeaderImage
      v-if="showCoverImage || showProfileImage"
      :cover-image-url="coverImageUrl"
      :profile-image-url="profileImageUrl"
      :show-cover-image="showCoverImage"
      :show-profile-image="showProfileImage"
      :cover-image-contain="coverImageContain"
      :profile-image-contain="profileImageContain"
      :title="title"
      :size="size"
      :cover-top-rounded="!showToolbar"
    />

    <DeckExodiaHeader
      v-if="showHeader"
      :title="title"
      :subtitle="subtitle"
      :avatar-icon="avatarIcon"
      :avatar-image-url="avatarImageUrl"
      :avatar-image-contain="avatarImageContain"
      :size="size"
      :title-line-clamp="titleLineClamp"
      :subtitle-line-clamp="subtitleLineClamp"
      :privacy="privacy"
      class="deck-exodia__header"
    >
      <template
        v-if="$slots['menu-items']"
        #menu-items
      >
        <slot name="menu-items" />
      </template>
      <template
        v-if="$slots['top-content']"
        #top-content
      >
        <slot name="top-content" />
      </template>
    </DeckExodiaHeader>

    <v-card-text
      v-if="$slots.default"
      class="pa-0 deck-exodia__content-wrapper text--primary"
    >
      <slot class="deck-exodia__content__main-content" />
    </v-card-text>

    <DeckExodiaFooter
      v-if="showFooter"
      :size="size"
      :footer-content="footerContent"
      :primary-btn-text="primaryBtnText"
      :primary-btn-icon="primaryBtnIcon"
      :secondary-btn-text="secondaryBtnText"
      :secondary-btn-icon="secondaryBtnIcon"
      :primary-btn-disabled="primaryBtnDisabled"
      :secondary-btn-disabled="secondaryBtnDisabled"
      @primary-btn-click="$emit('primaryBtnClick')"
      @secondary-btn-click="$emit('secondaryBtnClick')"
    />
  </v-card>
</template>
<script>
import DeckExodiaFooter from './_footer';
import DeckExodiaHeader from './_header';
import DeckExodiaHeaderImage from './_header-image';
import DeckExodiaToolbar from './_toolbar';

export default {
  name: 'DeckExodia',
  components: {
    DeckExodiaFooter,
    DeckExodiaHeader,
    DeckExodiaHeaderImage,
    DeckExodiaToolbar,
  },
  props: {
    small: { type: Boolean, default: false },
    to: { type: String, default: undefined },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    coverImageUrl: { type: String, default: null },
    coverImageContain: { type: Boolean, default: false },
    profileImageUrl: { type: String, default: null },
    profileImageContain: { type: Boolean, default: false },
    showCoverImage: { type: Boolean, default: false },
    showProfileImage: { type: Boolean, default: false },
    footerContent: { type: String, default: null },
    secondaryBtnDisabled: { type: Boolean, default: false },
    secondaryBtnText: { type: String, default: null },
    secondaryBtnIcon: { type: String, default: null },
    primaryBtnDisabled: { type: Boolean, default: false },
    primaryBtnText: { type: String, default: null },
    primaryBtnIcon: { type: String, default: null },
    avatarIcon: { type: String, default: null },
    avatarImageUrl: { type: String, default: null },
    avatarImageContain: { type: Boolean, default: false },
    showPreviousBtn: { type: Boolean, default: false },
    showNextBtn: { type: Boolean, default: false },
    navigationDirection: { type: String, default: 'vertical' },
    showExpandBtn: { type: Boolean, default: false },
    showCloseBtn: { type: Boolean, default: false },
    titleLineClamp: { type: [Number, String], default: 1 },
    subtitleLineClamp: { type: [Number, String], default: 2 },
    outlined: { type: Boolean, default: false },
    privacy: { type: String, default: null }, // TODO: Change to enum with privacy types
  },
  emits: ['previousBtnClick', 'nextBtnClick', 'expandBtnClick', 'closeBtnClick', 'primaryBtnClick', 'secondaryBtnClick'],
  computed: {
    exodiaClasses() {
      return {
        [`deck-exodia--${this.size}`]: true,
        'deck-exodia--without-top-padding': this.hideTopPadding,
        'deck-exodia--outlined': this.outlined,
      };
    },
    hideTopPadding() {
      return this.showToolbar || (!this.small && (this.showCoverImage || this.showProfileImage));
    },
    showHeader() {
      return this.title?.trim()
      || this.subtitle?.trim()
      || this.avatarIcon
      || this.avatarImageUrl
      || this.privacy
      || this.$slots['menu-items'];
    },
    showFooter() {
      return this.footerContent
      || this.primaryBtnText
      || this.primaryBtnIcon
      || this.secondaryBtnText
      || this.secondaryBtnIcon;
    },
    showToolbar() {
      return this.showPreviousBtn
      || this.showNextBtn
      || this.showExpandBtn
      || this.showCloseBtn
      || this.$slots['toolbar-items'];
    },
    size() {
      return this.small ? 'small' : 'large';
    },
  },
};
</script>
<style lang="scss">
.v-dialog .deck-exodia {
  .deck-exodia__content-wrapper {
    padding-inline: var(--z-s2);
  }
}

.deck-exodia {
  --deck-exodia-gap: var(--z-s6);

  --deck-exodia-border-color: transparent;
  --deck-exodia-border-color-hover: transparent;
  --deck-exodia-border-width: 0; // We have use cases where we want Exodia to be borderless AND full-width. and having just a transparent border makes it look like a very small padding, so we fully remove it.

  --deck-exodia-border-radius: var(--z-card-border-radius);

  display: flex;
  flex-direction: column;
  gap: var(--deck-exodia-gap);
  padding: var(--z-s4);
  min-width: 200px;
  box-shadow: none !important; // v-card override
  border-radius: var(--deck-exodia-border-radius) !important; // v-card override
  border-color: var(--deck-exodia-border-color) !important; // v-card override
  border-width: var(--deck-exodia-border-width);
  border-style: solid;
  transition: border-color 200ms ease;

  .v-row:not(.deck-exodia__header) {
    gap: var(--deck-exodia-gap) !important;
  }

  .v-col-md-6,
  .v-col-sm-6 {
    @media (min-width: 600px) {
      max-width: calc((100% / 2) - (var(--deck-exodia-gap) / 2)) !important;
    }
  }

  .v-col-md-4,
  .v-col-sm-4 {
    @media (min-width: 600px) {
      max-width: calc((100% / 3) - (var(--deck-exodia-gap) / 3)) !important;
    }
  }

  .subview-content.v-row,
  .deck-exodia__content-wrapper > .v-row{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .deck-exodia__content-wrapper {
    overflow-x: visible;
    flex: 1 auto;
  }

  &.v-card--link {
    &:hover {
      border-color: var(--deck-exodia-border-color-hover) !important;
    }

    &::before {
      opacity: 0 !important;
    }

    &::before, .v-ripple__container {
      border-radius: calc(var(--deck-exodia-border-radius) - var(--deck-exodia-border-width)) !important; // v-card override
    }

    &:hover:not(:focus)::before {
      opacity: 0.015;
    }
  }

  .deck-exodia__content__main-content {
    overflow-y: hidden;
  }
}

.deck-exodia--large {
  padding: var(--z-s8);
}

.deck-exodia--without-top-padding {
  padding-top: 0 !important;
}

.deck-exodia--outlined {
  --deck-exodia-border-color: var(--z-card-clickable-edge-color);
  --deck-exodia-border-color-hover: var(--z-card-clickable-edge-color-hover);
  --deck-exodia-border-width: 2px;

  &.v-card--link {
    &::before {
      opacity: 0 !important;
    }
  }
}
</style>
