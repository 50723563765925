<i18n lang="yaml">
pt:
  open: 'Abrir'
  close: 'Fechar'
  sidebar: 'barra lateral'
en:
  open: 'Open'
  close: 'Close'
  sidebar: 'sidebar'
</i18n>

<template>
  <v-navigation-drawer
    touchless
    touch
    width="304"
    :rail="Boolean(miniSidebar)"
    permanent
    :temporary="!miniSidebar && isMobile"
    class="sidebar"
  >
    <div
      v-click-outside="() => (isMobile && !miniSidebar) && closeSidebar()"
      class="sidebar__wrapper"
    >
      <div class="sidebar__category-column">
        <div class="sidebar__category-icons">
          <deck-button
            :tooltip-props="{ position: 'right' }"
            :text="`${miniSidebar ? t('open') : t('close')} ${t('sidebar')}`"
            class="sidebar__category-icon mb-3"
            color="controls"
            kind="ghost"
            icon="bars"
            is-ready
            @click="miniSidebar = !miniSidebar"
          />
          <deck-button
            v-for="({ category_icon, category_name, id }) in categoryHierarchyScoped"
            :key="id"
            :tooltip-props="{
              position: 'right',
              instant: true
            }"
            :toggled="category_name === currentCategoryName"
            :text="category_name"
            :icon="category_icon"
            class="sidebar__category-icon"
            color="controls"
            kind="ghost"
            is-ready
            @click="changeCategory(category_name)"
          />
        </div>
      </div>
      <div class="sidebar__navigation-column fill-height d-flex flex-column">
        <div
          v-if="currentCategory"
          class="sidebar__current-category"
        >
          <deck-icon
            :name="currentCategory.category_icon"
            fixed-width
          />
          <span>{{ currentCategory.category_name }}</span>
        </div>

        <DraggableListV2
          :model-value="items"
          :active-item-id="$route.params.viewId"
          class="flex-1"
          collapsed
          :dense="false"
          :draggable="false"
          disabled
          @link-clicked="handleLinkClick"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from '~/assets/javascript/modules/vuex';
import { viewBelongsToScope } from '~/assets/javascript/utils';
import DraggableListV2 from '~/components/DraggableListV2';

export default defineComponent({
  name: 'Sidebar',
  components: {
    DraggableListV2,
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapState('viewsMenu', ['views']),
      ...mapState('workspace', ['currentCategoryName']),
      ...mapGetters('workspace', ['categoryHierarchy', 'categoryHierarchyScoped', 'viewsOrder', 'firstScopedViewId', 'currentScope']),
      ...mapMutations('workspace', ['setCurrentCategoryName']),
    };
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    currentCategory() {
      return this.categoryHierarchy.find(({ category_name: name }) => name === this.currentCategoryName);
    },
    miniSidebar: {
      get() {
        return this.$store.state.viewsMenu.miniSidebar;
      },
      set(v) {
        return this.$store.commit('viewsMenu/setMiniSidebar', v);
      },
    },
    items() {
      if (this.views.length === 0 || this.viewsOrder.length === 0) return [];

      const generateItems = items => items.map((item) => {
        if (item.items) {
          const items = generateItems(item.items);

          if (items.length === 0) return null;

          return {
            draggable: false,
            id: item.id,
            title: item.group_name,
            active: items.some(i => i.active),
            items,
          };
        }

        const view = this.views.find(({ id }) => id === item.id);

        if (!view || !viewBelongsToScope(view, this.currentScope, this.$auth.user)) return null;

        return {
          draggable: false,
          id: view.id,
          title: view.page_name,
          to: this.localePath({
            name: 't-tenantSlug-m-viewId',
            params: { tenantSlug: this.$route.params.tenantSlug, viewId: view.id },
          }),
          active: view.id === this.$route.params.viewId,
        };
      }).filter(Boolean);

      return generateItems(this.viewsOrder);
    },
  },
  mounted() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler, { passive: true });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      const isMobile = window.innerWidth < 768;

      if (isMobile !== this.isMobile) {
        if (isMobile && !this.miniSidebar) {
          this.miniSidebar = true;
        } else if (!isMobile && this.miniSidebar) {
          this.miniSidebar = window.localStorage?.getItem('miniSidebar') || false;
        }
      }

      this.isMobile = isMobile;
    },
    handleLinkClick() {
      if (!this.isMobile) return;
      this.miniSidebar = true;
    },
    changeCategory(categoryName) {
      this.setCurrentCategoryName(categoryName);

      if (this.firstScopedViewId) {
        this.$router.push(this.localeRoute({
          name: 't-tenantSlug-m-viewId',
          params: {
            tenantSlug: this.$route.params.tenantSlug,
            viewId: this.firstScopedViewId,
          },
        }));
      }
    },
    closeSidebar() {
      this.miniSidebar = true;
    },
  },
});
</script>

<style lang="scss">
.sidebar {
  z-index: 7 !important; // Right below v-menus
  top: 56px !important;
  background-color: var(--z-side-bar-background-color) !important;
}

.sidebar__zazos-name {
  color: #6b6f75 !important;
  font-family: 'Inter',sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.sidebar__current-category {
  display: flex;
  align-items: center;
  height: 36px;
  gap: 8px;
  padding: 12px 10px;
  box-sizing: content-box;
}

.sidebar__current-category-icon {
  border: 2px solid #E3E3E3;
  width: 38px;
  border-radius: 8px;
}

.v-navigation-drawer__border {
  background-color: var(--z-color-shade-light-6) !important;
}

.sidebar__wrapper {
  --category-column-width: 60px;

  display: flex;
  min-height: 100%;
}

.sidebar__divider {
  margin: 16px 0;
  border-width: 0.5px !important;
  border-color: #9b9b9b !important;
  min-width: 100% !important;
}

.sidebar__category-column {
  flex-shrink: 0;
  width: var(--category-column-width);
  background-color: var(--z-categories-bar-background-color);
}

.sidebar__navigation-column {
  width: calc(304px - var(--category-column-width));
}

.sidebar__category-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-block: 12px;
}
</style>
