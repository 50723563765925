
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexrPbNmYfClEMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/index.vue?macro=true";
import { default as indexPk0dUX1KrbMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/login/index.vue?macro=true";
import { default as indexrCLjvL7uvlMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/magic-link/index.vue?macro=true";
import { default as not_45yetXRxQ74lpCPMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/not-yet.vue?macro=true";
import { default as index5hK2u0JaQsMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/reset-password/index.vue?macro=true";
import { default as set_45workspaceRgg1OaXm8qMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/set-workspace.vue?macro=true";
import { default as indexsWDc2Y4MxgMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/sign-up/index.vue?macro=true";
import { default as _91appName_93sizRyaGG5dMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/store/apps/[appName].vue?macro=true";
import { default as indexQbcsB66jIXMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/store/index.vue?macro=true";
import { default as indextt5PVqNGFIMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue?macro=true";
import { default as indexFtMTxGczytMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/index.vue?macro=true";
import { default as indexCphf4ZFP0RMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue?macro=true";
import { default as indexlTN4WrTp0qMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/login/index.vue?macro=true";
import { default as indexKBxWij6MdZMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue?macro=true";
import { default as indexhlsiJIrznzMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as new0PDuwkytIAMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue?macro=true";
import { default as account_45configurationbTMKTfhlyXMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue?macro=true";
import { default as index2xe6twMW7tMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/index.vue?macro=true";
import { default as mN1YnEcYBSOMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m.vue?macro=true";
import { default as indexCqDLamVfEGMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue?macro=true";
import { default as indexYYjDYj1bJjMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as indexjGJ1dAnUflMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/index.vue?macro=true";
import { default as reset_45passwordgsHe2ZcvuAMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue?macro=true";
import { default as indexVS5Dzt9OyeMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue?macro=true";
import { default as indexekLNZcp3XoMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue?macro=true";
import { default as history39EYWcConrMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue?macro=true";
import { default as indexKgXgv8RsJuMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue?macro=true";
import { default as index6jzyh6FN4oMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue?macro=true";
import { default as importEJS1JRyXx0Meta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue?macro=true";
import { default as indexXxGlZDtPLnMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue?macro=true";
import { default as schemanj20b2BpFYMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue?macro=true";
import { default as indexMZ3YtlaDLvMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue?macro=true";
import { default as indexBaBzhKGB0RMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue?macro=true";
import { default as _91id_932OJAnICChjMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue?macro=true";
import { default as indexw9ziehVqFRMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue?macro=true";
import { default as indexEfp1QVOrwnMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue?macro=true";
import { default as index8wDGXim41mMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue?macro=true";
import { default as indexgdKcNcpnOmMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue?macro=true";
import { default as indexjJeoKrKOwJMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue?macro=true";
import { default as _91id_93uSwTKQuaHiMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue?macro=true";
import { default as index5yJO2uY5JyMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue?macro=true";
import { default as integrationsSRUBH1IbVZMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue?macro=true";
import { default as indexuvQnjKjA3vMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue?macro=true";
import { default as index59Nu7K1gQJMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue?macro=true";
import { default as indexTtyW1DwyOaMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue?macro=true";
import { default as _91id_93d6YrfoE2epMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue?macro=true";
import { default as indexQFXnhj5ea1Meta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue?macro=true";
import { default as newFXQqamoEQNMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue?macro=true";
import { default as user_45groupsw1gHiNMElNMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue?macro=true";
import { default as index9XxAEmGFmJMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue?macro=true";
import { default as settingszJk73eWwCFMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue?macro=true";
import { default as studio2H3WyDHQXKMeta } from "/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexrPbNmYfClEMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    meta: indexrPbNmYfClEMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "magic-link___en",
    path: "/en/magic-link",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "magic-link___pt",
    path: "/magic-link",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "not-yet___en",
    path: "/en/not-yet",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "not-yet___pt",
    path: "/not-yet",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pt",
    path: "/reset-password",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "set-workspace___en",
    path: "/en/set-workspace",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "set-workspace___pt",
    path: "/set-workspace",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "store-apps-appName___en",
    path: "/en/store/apps/:appName()",
    meta: _91appName_93sizRyaGG5dMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store-apps-appName___pt",
    path: "/store/apps/:appName()",
    meta: _91appName_93sizRyaGG5dMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store___en",
    path: "/en/store",
    meta: indexQbcsB66jIXMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "store___pt",
    path: "/store",
    meta: indexQbcsB66jIXMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___en",
    path: "/en/t/:tenantSlug()/email-validation",
    meta: indextt5PVqNGFIMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___pt",
    path: "/t/:tenantSlug()/email-validation",
    meta: indextt5PVqNGFIMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug___en",
    path: "/en/t/:tenantSlug()",
    meta: indexFtMTxGczytMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug___pt",
    path: "/t/:tenantSlug()",
    meta: indexFtMTxGczytMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug-loading___en",
    path: "/en/t/:tenantSlug()/loading",
    meta: indexCphf4ZFP0RMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-loading___pt",
    path: "/t/:tenantSlug()/loading",
    meta: indexCphf4ZFP0RMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-login___en",
    path: "/en/t/:tenantSlug()/login",
    meta: indexlTN4WrTp0qMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: "t-tenantSlug-login___pt",
    path: "/t/:tenantSlug()/login",
    meta: indexlTN4WrTp0qMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: mN1YnEcYBSOMeta?.name,
    path: "/en/t/:tenantSlug()/m",
    meta: mN1YnEcYBSOMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___en",
    path: ":viewId()",
    meta: indexKBxWij6MdZMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___en",
    path: ":viewId()/r/:recordId()",
    meta: indexhlsiJIrznzMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___en",
    path: ":viewId()/r/new",
    meta: new0PDuwkytIAMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___en",
    path: "account-configuration",
    meta: account_45configurationbTMKTfhlyXMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___en",
    path: "",
    meta: index2xe6twMW7tMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: mN1YnEcYBSOMeta?.name,
    path: "/t/:tenantSlug()/m",
    meta: mN1YnEcYBSOMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___pt",
    path: ":viewId()",
    meta: indexKBxWij6MdZMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___pt",
    path: ":viewId()/r/:recordId()",
    meta: indexhlsiJIrznzMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___pt",
    path: ":viewId()/r/new",
    meta: new0PDuwkytIAMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___pt",
    path: "account-configuration",
    meta: account_45configurationbTMKTfhlyXMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___pt",
    path: "",
    meta: index2xe6twMW7tMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-p-viewId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()",
    meta: indexCqDLamVfEGMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId___pt",
    path: "/t/:tenantSlug()/p/:viewId()",
    meta: indexCqDLamVfEGMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexYYjDYj1bJjMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___pt",
    path: "/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexYYjDYj1bJjMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p___en",
    path: "/en/t/:tenantSlug()/p",
    meta: indexjGJ1dAnUflMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-p___pt",
    path: "/t/:tenantSlug()/p",
    meta: indexjGJ1dAnUflMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-reset-password___en",
    path: "/en/t/:tenantSlug()/reset-password",
    meta: reset_45passwordgsHe2ZcvuAMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-reset-password___pt",
    path: "/t/:tenantSlug()/reset-password",
    meta: reset_45passwordgsHe2ZcvuAMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-sign-up___en",
    path: "/en/t/:tenantSlug()/sign-up",
    meta: indexVS5Dzt9OyeMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sign-up___pt",
    path: "/t/:tenantSlug()/sign-up",
    meta: indexVS5Dzt9OyeMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___en",
    path: "/en/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___pt",
    path: "/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: studio2H3WyDHQXKMeta?.name,
    path: "/en/t/:tenantSlug()/studio",
    meta: studio2H3WyDHQXKMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___en",
    path: "automations/:id()/history",
    meta: history39EYWcConrMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___en",
    path: "automations/:id()",
    meta: indexKgXgv8RsJuMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___en",
    path: "automations",
    meta: index6jzyh6FN4oMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___en",
    path: "data/:id()/import",
    meta: importEJS1JRyXx0Meta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___en",
    path: "data/:id()",
    meta: indexXxGlZDtPLnMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___en",
    path: "data/:id()/schema",
    meta: schemanj20b2BpFYMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___en",
    path: "data",
    meta: indexMZ3YtlaDLvMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___en",
    path: "",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___en",
    path: "pages/:id()",
    meta: _91id_932OJAnICChjMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___en",
    path: "pages",
    meta: indexw9ziehVqFRMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingszJk73eWwCFMeta?.name,
    path: "settings",
    meta: settingszJk73eWwCFMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___en",
    path: "app-builder",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___en",
    path: "authentication",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___en",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___en",
    path: "",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsSRUBH1IbVZMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___en",
    path: "",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___en",
    path: "internal",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___en",
    path: "system-entities",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___en",
    path: "tokens",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsw1gHiNMElNMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___en",
    path: "",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___en",
    path: "new",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___en",
    path: "users",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  },
  {
    name: studio2H3WyDHQXKMeta?.name,
    path: "/t/:tenantSlug()/studio",
    meta: studio2H3WyDHQXKMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___pt",
    path: "automations/:id()/history",
    meta: history39EYWcConrMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___pt",
    path: "automations/:id()",
    meta: indexKgXgv8RsJuMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___pt",
    path: "automations",
    meta: index6jzyh6FN4oMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___pt",
    path: "data/:id()/import",
    meta: importEJS1JRyXx0Meta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___pt",
    path: "data/:id()",
    meta: indexXxGlZDtPLnMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___pt",
    path: "data/:id()/schema",
    meta: schemanj20b2BpFYMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___pt",
    path: "data",
    meta: indexMZ3YtlaDLvMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___pt",
    path: "",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___pt",
    path: "pages/:id()",
    meta: _91id_932OJAnICChjMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___pt",
    path: "pages",
    meta: indexw9ziehVqFRMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingszJk73eWwCFMeta?.name,
    path: "settings",
    meta: settingszJk73eWwCFMeta || {},
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___pt",
    path: "app-builder",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___pt",
    path: "authentication",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___pt",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___pt",
    path: "",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsSRUBH1IbVZMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___pt",
    path: "",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___pt",
    path: "internal",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___pt",
    path: "system-entities",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___pt",
    path: "tokens",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsw1gHiNMElNMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___pt",
    path: "",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___pt",
    path: "new",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___pt",
    path: "users",
    component: () => import("/codebuild/output/src4022050303/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  }
]