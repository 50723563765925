export const DEFAULT_PAGE_STATE_COMPONENTS = Object.freeze({
  index: {
    main_section: {
      main_content: [],
    },
  },
  single_item: {
    main_section: {
      main_content: [],
    },
  },
});

export const HELPER_TEXT_ATTRIBUTES = Object.freeze([
  'helper_text',
  'show_helper_text_tooltip',
]);

export const LABEL_ATTRIBUTES = Object.freeze([
  'label',
  'label_style',
]);

export const MINICARD_LIST_STYLE_ATTRIBUTE = Object.freeze('list_style');
export const MINICARD_ITEMS_ATTRIBUTE = Object.freeze('items_style');
