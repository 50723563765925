<i18n lang="yaml">
pt:
  areYouSure: "Você tem certeza?"
  confirm: "Confirmar"
  confirmName: "Para continuar, digite o nome abaixo:"
  doNotSeeThisAgain: "Não mostrar essa mensagem novamente"
  typeTheName: "Digite o nome"
  warningDeleteText:
    cannotDeleteAutomatically: "Este recurso não pode ser deletado enquanto houver dependências com remoção manual. Por favor, veja a lista abaixo, remova as dependências manuais e tente novamente."
    View: 'Esta página será deletada, mas seus dados não serão afetados.
      | Esta página será deletada, sua dependência será removida, mas seus dados não serão afetados.
      | Esta página será deletada, suas dependências serão removidas, mas seus dados não serão afetados.'
    Sheet: 'Esta base de dados será deletada.
      | Esta base de dados será deletada e sua dependência será removida.
      | Esta base de dados será deletada e suas dependências serão removidas.'
    Field: 'Esta coluna será deletada.
      | Esta coluna será deletada e sua dependência será removida:
      | Esta coluna será deletada e suas dependências serão removidas:'
    GlobalParameter: 'Este parâmetro será deletado.'

en:
  areYouSure: "Are you sure?"
  confirm: "Confirm"
  confirmName: "To continue, type the name above:"
  doNotSeeThisAgain: "Do not show this message again"
  typeTheName: "Type the name"
  warningDeleteText:
    cannotDeleteAutomatically: "This resource cannot be deleted while manual remove dependencies are present. Please, see the list below, remove the manual dependencies and try it again."
    View: 'This page will be deleted, but your data will not be affected.
      | This page will be deleted, its dependency will be removed, but your data will not be affected.
      | This page will be deleted, its dependencies will be removed, but your data will not be affected.'
    Sheet: 'This sheet will be deleted.
      | This sheet will be deleted and its dependency will be removed.
      | This sheet will be deleted and its dependencies will be removed.'
    Field: 'This column will be deleted.
      | This column will be deleted and its dependency will be removed:
      | This column will be deleted and its dependencies will be removed:'
    GlobalParameter: 'This parameter will be deleted.'
</i18n>

<template>
  <DialogLoading v-if="isLoading" />

  <v-card
    v-else-if="!doNotSeeThisAgain"
    class="confirm-action-dialog"
  >
    <v-card-item>
      <v-card-title>
        {{ title || t('areYouSure') }}
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <div
        v-if="confirmName && showConfirmAction"
        class="mb-2"
      >
        <p class="mb-2 text-body-2 text-medium-emphasis">
          {{ t('confirmName') }} <strong>{{ name }}</strong>
        </p>

        <deck-text-field
          v-model="typedName"
          :aria-label="t('typeTheName')"
          size="medium"
          :placeholder="t('typeTheName')"
          required
          autofocus
          :color="warning ? 'error' : 'primary'"
          @keydown="holdInputEnterKeyDown"
        />
      </div>

      <p
        v-if="!warning"
        v-html="text"
      />

      <template v-if="warning">
        <deck-alert
          :kind="warningType"
          :text="warningText"
          size="small"
        />

        <div
          v-if="itemDependencies.length > 0"
          class="mt-2"
        >
          <p
            v-for="dependency in itemDependencies"
            :key="dependency"
            class="text-caption text--primary font-weight-bold mb-2"
          >
            {{ dependency }}
          </p>
        </div>

        <DependenciesList
          v-if="dependencies && dependencies.length > 0"
          class="mt-2"
          :items="dependencies"
        />
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-checkbox
        v-if="doNotSeeThisAgainSettings.enabled"
        v-model="doNotSeeThisAgain"
        hide-details
        size="small"
        class="pr-2"
        :label="t('doNotSeeThisAgain')"
      />

      <v-spacer />

      <deck-button
        v-test-id="'confirm-action-dialog-close-button'"
        is-ready
        kind="ghost"
        color="controls"
        @click="cancel"
      >
        {{ cancelText || $t('global.cancel') }}
      </deck-button>
      <deck-button
        v-if="showConfirmAction"
        is-ready
        :color="warning && warningType === 'destructive' ? 'destructive': confirmColor"
        :disabled="!canConfirm"
        @click="confirm"
      >
        {{ confirmText || t('confirm') }}
      </deck-button>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { mapMutations } from '~/assets/javascript/modules/vuex';
import { slugify } from '~/assets/javascript/utils';
import DialogLoading from '~/components/dialogs/dialog-loading';

export default defineComponent({
  name: 'DialogConfirmAction',
  components: {
    DependenciesList: defineAsyncComponent(() => import('~/components/dependencies-list')),
    DialogLoading,
  },
  props: {
    title: {
      type: String,
      default: () => null,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    warningType: {
      type: String,
      default: 'error',
    },
    confirmName: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
    resourceType: {
      type: String,
      default: null,
    },
    doNotSeeThisAgainSettings: {
      type: Object,
      default: () => ({ enabled: false, key: '' }),
    },
    itemDependencies: {
      type: Array,
      default: () => [],
    },
    confirmColor: {
      type: String,
      default: 'primary',
    },
    blockConfirmAction: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: null,
    },
    cancelText: {
      type: String,
      default: null,
    },
  },
  emits: ['confirm', 'cancel'],
  setup() {
    return {
      ...mapMutations('dialog', ['closeDialog']),
      t: useI18n().t,
    };
  },
  data() {
    return {
      typedName: '',
      dependencies: [],
      isLoading: true,
    };
  },
  computed: {
    canConfirm() {
      return !this.confirmName || slugify(this.typedName) === slugify(this.name);
    },
    hasManualDeletionDependency() {
      return this.dependencies.some(dependency => dependency.prevent_deletion || dependency.source_parent_prevent_deletion);
    },
    warningText() {
      if (this.hasManualDeletionDependency) return this.t('warningDeleteText.cannotDeleteAutomatically');
      // Multiplicate the itemDependencies length (by 2) to always display plural because it agreggates the dependencies
      if (this.resourceType && this.resourceId) return this.t(`warningDeleteText.${this.resourceType}`, this.dependencies.length + (this.itemDependencies.length * 2));

      return this.text;
    },
    showConfirmAction() {
      return !this.hasManualDeletionDependency && !this.blockConfirmAction;
    },
    doNotSeeAgainKey() {
      return `doNotSeeThisAgain.${this.doNotSeeThisAgainSettings.key}`;
    },
    doNotSeeThisAgain: {
      get() {
        return JSON.parse(window.localStorage?.getItem(this.doNotSeeAgainKey));
      },
      set(value) {
        window.localStorage?.setItem(this.doNotSeeAgainKey, JSON.stringify(value));
      },
    },
  },
  async mounted() {
    if (this.doNotSeeThisAgainSettings.enabled && this.doNotSeeThisAgain) {
      this.confirm();
      return;
    }

    if (this.resourceId && this.resourceType) {
      await this.fetchDependencies();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    async confirm() {
      this.$emit('confirm');

      // Wait for the dialog complete render to close
      // doNotSeeThisAgain skip can be more quickly than the dialog render
      await nextTick();
      this.closeDialog();
    },
    holdInputEnterKeyDown(event) {
      if (event.key !== 'Enter' || !this.canConfirm) return;

      this.confirm();
    },
    cancel() {
      this.$emit('cancel');
      this.closeDialog();
    },
    async fetchDependencies() {
      try {
        this.dependencies = await this.$apiClient.builder.entityDependencies.listByTargetIdAndType(
          this.resourceId,
          this.resourceType,
        );
      } catch (error) {
        this.$errorRescue(this, error, 'fetchDependencies');
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
