import { appInstallations } from './appInstallations';
import { apps } from './apps';
import { categories } from './categories';
import { entityDependencies } from './entityDependencies';
import { globalParameters } from './globalParameters';
import { providerIntegrations } from './providerIntegrations';
import { schema } from './schema';
import { secrets } from './secrets';
import { ssoProviders } from './ssoProviders';
import { tools } from './tools';
import { views } from './views';
import { workflowExecutions } from './workflowExecutions';
import { workflowSchemaParameters } from './workflowSchemaParameters';
import { workflowTriggerViews } from './workflowTriggerViews';

export const builder = ($http: HttpApi) => ({
  appInstallations: appInstallations($http),
  apps: apps($http),
  categories: categories($http),
  entityDependencies: entityDependencies($http),
  globalParameters: globalParameters($http),
  providerIntegrations: providerIntegrations($http),
  schema: schema($http),
  secrets: secrets($http),
  ssoProviders: ssoProviders($http),
  tools: tools($http),
  views: views($http),
  workflowExecutions: workflowExecutions($http),
  workflowSchemaParameters: workflowSchemaParameters($http),
  workflowTriggerViews: workflowTriggerViews($http),
});
