<template>
  <v-alert
    :model-value="modelValue"
    :type="kind"
    :closable="dismissible"
    :density="computedDensity"
    :icon="null"
    :style="cssProps"
    :class="classes"
    variant="tonal"
    class="deck-alert"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #prepend>
      <deck-icon
        :name="computedIcon"
        :color="kind"
        kind="solid"
        fixed-width
      />
    </template>

    <template
      v-if="title"
      #title
    >
      {{ title }}
    </template>

    <template
      v-if="text"
      #text
    >
      {{ text }}
    </template>
    <template
      v-else-if="!title"
      #text
    >
      <slot name="default" />
    </template>

    <template
      v-if="dismissible"
      #close="{ props }"
    >
      <deck-button
        :text="$t('global.close')"
        is-ready
        icon="circle-xmark"
        kind="ghost"
        color="currentColor"
        v-bind="props"
      />
    </template>
  </v-alert>
</template>

<script>
import DeckButton from '~/deck/button';
import DeckIcon from '~/deck/icon';
import { TRANSLUCENT_BLACK, TRANSLUCENT_WHITE } from '../utils/color';

/**
 * A custom alert component extending on Vuetify's v-alert.
 * @component
 */
export default {
  name: 'DeckAlert',

  components: {
    DeckButton,
    DeckIcon,
  },

  props: {
    /**
     * Model to control the alert shown state.
     * @type {boolean}
     * @default true
     */
    modelValue: {
      type: Boolean,
      default: true,
    },

    /**
     * Title to be rendered before the alert content.
     * @type {string}
     * @default ''
     */
    title: {
      type: String,
      default: undefined,
    },

    /**
     * Simple text only content to be rendered inside the alert.
     * @type {string}
     * @default ''
     */
    text: {
      type: String,
      default: undefined,
    },

    /**
     * Specifies the kind of the alert.
     * @type {'info' | 'warning' | 'error' | 'success' |  string}
     * @default 'info'
     */
    kind: {
      type: String,
      default: 'info',
    },

    /**
     * Determines whether the alert can be dismissed.
     * @type {boolean}
     * @default false
     */
    dismissible: {
      type: Boolean,
      default: false,
    },

    /**
     * Size of the alert. When small, the icon will render inline with the content.
     * @type {'dense' | 'small' | 'medium' }
     * @default 'medium'
     */
    size: {
      type: String,
      default: 'medium',
    },

    /**
     * The max width of the alert.
     * @type {string}
     * @default '100%'
     */
    maxWidth: {
      type: String,
      default: '100%',
    },
    /**
     * The icon to be displayed before the label.
     * @type {string}
     * @default undefined
     */
    icon: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      darkMode: useZazos().darkMode,
    };
  },
  computed: {
    classes() {
      return {
        [`text-${this.kind}`]: true,
        [`deck-alert--${this.size}`]: true,
      };
    },
    cssProps() {
      return {
        '--deck-alert-color-text': this.colorText,
        '--deck-alert-max-width': this.maxWidth,
      };
    },
    computedIcon() {
      return this.icon || new Map([
        ['info', 'info-circle'],
        ['warning', 'exclamation-triangle'],
        ['error', 'exclamation-circle'],
        ['success', 'check-circle'],
      ]).get(this.kind);
    },
    computedDensity() {
      if (this.size === 'dense') return 'compact';
      if (this.size === 'small') return 'comfortable';
      return 'default';
    },
    colorText() {
      return this.darkMode ? TRANSLUCENT_WHITE : TRANSLUCENT_BLACK;
    },
  },
};
</script>
<style lang="scss">
.deck-alert {
  --deck-alert-padding: var(--z-s2);
  --deck-alert-border-radius: var(--z-border-radius-elevated);
  --deck-alert-font-size: var(--z-font-size-medium);
  --deck-alert-font-weight: var(--z-font-weight-regular);

  overflow: hidden;
  max-width: var(--deck-alert-max-width);

  &.v-alert {
    padding-left: var(--deck-alert-padding);
    padding-right: var(--deck-alert-padding);
  }

  .v-alert__prepend {
    margin-inline-end: var(--z-s2);
  }

  .v-alert__content {
    color: var(--deck-alert-color-text);
    font-size: var(--deck-alert-font-size);
    font-weight: var(--deck-alert-font-weight);
    text-wrap: pretty;
  }

  .v-alert-title {
    font-size: var(--z-font-size-medium);
    font-weight: var(--z-font-weight-bold);
  }
}

.deck-alert--small, .deck-alert--dense {
  --deck-alert-padding: var(--z-s1);
  --deck-alert-border-radius: var(--z-border-radius-base);
  --deck-alert-font-size: var(--z-font-size-small);
}

.deck-alert--dense {
  --deck-alert-font-size: var(--z-font-size-xsmall);
  --deck-alert-font-weight: var(--z-font-weight-medium);
}
</style>
