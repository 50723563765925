<template>
  <transition
    :name="`deck-snackbar--slide-from-${transitionDirection}`"
    appear
  >
    <div v-if="showSnackbar">
      <v-snackbar
        v-model="showSnackbar"
        :style="cssProps"
        :location="location"
        :color="color"
        :timeout="timeout"
        :content-class="`${contentClass} text-${color}`"
        :class="classes"
        variant="flat"
        :transition="false"
        class="deck-snackbar"
        :timer="timeout > 0 ? color : false"
        v-bind="$attrs"
      >
        <deck-icon
          v-if="computedIcon"
          :name="computedIcon"
          :color="computedIconColor"
          class="deck-snackbar__icon"
        />
        <div class="deck-snackbar__message">
          <slot>
            <p class="mb-0">
              {{ text }}
            </p>
          </slot>
        </div>

        <deck-button
          v-if="!hideClose"
          :text="$t('global.close')"
          is-ready
          icon="circle-xmark"
          kind="ghost"
          color="currentColor"
          class="deck-snackbar__close"
          @click="handleShow(false)"
        />

        <template #actions>
          <!--
        @slot Slot for custom actions. Close action is always added automatically after these.
      -->
          <slot
            name="action"
          />
        </template>
      </v-snackbar>
    </div>
  </transition>
</template>

<script>
import DeckIcon from '~/deck/icon';
import DeckButton from '~/deck/button';
import { TRANSLUCENT_BLACK, TRANSLUCENT_WHITE } from '../utils/color';

export default {
  name: 'DeckSnackbar',
  components: {
    DeckIcon,
    DeckButton,
  },
  inheritAttrs: false,

  props: {
    /**
     * Model that controls whether the snackbar is visible or not.
     * @type {boolean}
     * @default false
     */
    modelValue: {
      type: Boolean,
      default: false,
    },

    /**
     * Positions the snackbar to the left of the screen.
     * @type {boolean}
     * @default false
     */
    left: {
      type: Boolean,
      default: false,
    },

    /**
     * Positions the snackbar to the right of the screen.
     * @type {boolean}
     * @default false
     */
    right: {
      type: Boolean,
      default: false,
    },

    /**
     * Positions the snackbar to the top of the screen.
     * @type {boolean}
     * @default false
     */
    top: {
      type: Boolean,
      default: false,
    },

    /**
     * Positions the snackbar to the bottom of the screen. This is the default
     * position when no position prop is defined.
     * @type {boolean}
     * @default false
     */
    bottom: {
      type: Boolean,
      default: false,
    },

    /**
     * Sets the color of the snackbar. If none is set, the snackbar will be black by default.
     * @type {'info' | 'warning' | 'error' | 'success' | string}
     */
    color: {
      type: String,
      default: undefined,
    },

    /**
     * Sets the timeout (in milliseconds) for closing the snackbar.
     * `-1` will keep it always open, which is useful to prompt users to take
     * some action, or even pause the timeout programatically.
     * @type {number}
     * @default 6000
     */
    timeout: {
      type: Number,
      default: 6000,
    },

    /**
     * Sets a custom CSS class for the snackbar content.
     * @type {string}
     * @default ''
     */
    contentClass: {
      type: String,
      default: '',
    },

    /**
     * The text message to display in the snackbar.
     * @type {string}
     */
    text: {
      type: [String, Error],
      default: '',
    },

    /**
     * A Font Awesome icon to display in the snackbar.
     * @type {string}
     */
    icon: {
      type: String,
      default: '',
    },

    /**
     * Whether to hide the close button or not.
     * @type {boolean}
     * @default false
     */
    hideClose: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the snackbar is anchored (position: fixed) to the screen or not.
     * Use this when you want free control over the snackbar's position.
     * @type {boolean}
     * @default false
     */
    unanchored: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether to show the snackbar instantly without a transition or not.
     * @type {boolean}
     * @default false
     */
    instant: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return { darkMode: useZazos().darkMode };
  },
  data() {
    return {
      showSnackbar: false,
    };
  },
  computed: {
    cssProps() {
      return {
        '--deck-snackbar-color-text': this.colorText,
        '--transition-from-direction': this.transitionTranslateValue,
        ...this.instant && { '--transition-duration': '0ms' }, // Disable transition when `instant`, otherwise let the CSS classes handle this value.
      };
    },
    colorText() {
      if (!this.color) return this.darkMode ? TRANSLUCENT_BLACK : TRANSLUCENT_WHITE;
      return this.darkMode ? TRANSLUCENT_WHITE : TRANSLUCENT_BLACK;
    },
    computedIcon() {
      return new Map([
        ['info', 'info-circle'],
        ['warning', 'exclamation-triangle'],
        ['error', 'exclamation-circle'],
        ['success', 'check-circle'],
      ]).get(this.color) || this.icon;
    },
    computedIconColor() {
      return new Map([
        ['warning', 'warning'],
        ['info', 'info'],
        ['error', 'error'],
        ['success', 'success'],
      ]).get(this.color) || this.color;
    },
    transitionDirection() {
      if (this.left) return 'left';
      if (this.right) return 'right';
      if (this.top) return 'top';
      if (this.bottom) return 'bottom';
      return 'bottom'; // Default from bottom
    },
    transitionTranslateValue() {
      return new Map([
        ['left', 'translateX(-100%)'],
        ['right', 'translateX(100%)'],
        ['top', 'translateY(-100%)'],
        ['bottom', 'translateY(100%)'],
      ]).get(this.transitionDirection) || 'translateY(100%)'; // Default from bottom
    },
    location() {
      return [
        this.left && 'left',
        this.right && 'right',
        this.top && 'top',
        this.bottom && 'bottom',
      ].filter(Boolean).join(' ');
    },
    classes() {
      return {
        'deck-snackbar--unanchored': this.unanchored,
        'deck-snackbar--make-tonal': !!this.color,
      };
    },
  },

  watch: {
    modelValue: {
      handler(newValue) {
        this.handleShow(newValue);
      },
      immediate: true, // To allow triggering the snackbar on mount when using the component with `modelValue` initially set to true.
    },
  },

  methods: {
    handleShow(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.showSnackbar = newValue;

      /**
       * Triggered when the snackbar is shown or hidden.
       * @event update:modelValue
       */
      this.$emit('update:modelValue', newValue);
    },
  },
};
</script>
<style lang="scss">
.deck-snackbar {
  .v-snackbar__wrapper {
    // We now control visibility with `v-show` in order to fire transitions from
    // our side, so we override Cuetify's `display: none` when closing the snackbar.
    display: flex !important;

    flex-direction: column;
    border-radius: 12px !important;
    overflow: hidden;
    box-shadow: none !important;
  }

  &--make-tonal {
    .v-snackbar__wrapper {
      background-color: rgb(var(--v-theme-background)) !important; // Reset to use the underlay color
    }

    .v-snackbar__underlay {
      background-color: currentColor;
      border-radius: inherit;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0.25;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  .v-snackbar__content {
    padding: 8px !important;
    display: grid !important;
    align-items: center !important;
    width: 100%;
    grid-template:
      ' icon         content  close       ' minmax(min-content, 36px)
      ' .            content  .           ' 1fr
      / min-content  1fr      min-content
    ;
  }

  .deck-snackbar__icon {
    grid-area: icon;
    width: 36px; // Same as close button
  }

  .deck-snackbar__message {
    grid-area: content;
    padding-inline: 8px;
    color: var(--deck-snackbar-color-text, currentColor) !important;
  }

  .deck-snackbar__close {
    grid-area: close;
  }

  .v-snackbar__actions {
    width: 100%;
    padding: 16px;
    padding-top: 0;
    justify-content: flex-end;
    margin: 0 !important;

    &:empty {
      padding: 0;
    }
  }
}

// Transition definitions for every direction
$directions: right, left, top, bottom;

@each $direction in $directions {
  .deck-snackbar--slide-from-#{$direction}-move,
  .deck-snackbar--slide-from-#{$direction}-enter-active,
  .deck-snackbar--slide-from-#{$direction}-leave-active {
    transition-duration: var(--transition-duration, 750ms);
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
    transition-property: opacity, transform;
  }

  .deck-snackbar--slide-from-#{$direction}-enter,
  .deck-snackbar--slide-from-#{$direction}-leave-to {
    transform: var(--transition-from-direction);
    opacity: 0;
  }

  .deck-snackbar--slide-from-#{$direction}-move {
    transition-duration: var(--transition-duration, 150ms);
  }
}
</style>
