import { pick } from 'lodash';
import { HELPER_TEXT_ATTRIBUTES, LABEL_ATTRIBUTES } from '~/assets/javascript/constants';

export default class FilterBarInfoComponentFromOptionBuilder {
  filterBarInfoComponentOption: any;

  static build(infoComponentOption) {
    return new FilterBarInfoComponentFromOptionBuilder(infoComponentOption).build();
  }

  constructor(infoComponentOption) {
    this.filterBarInfoComponentOption = infoComponentOption;
  }

  build() {
    return {
      ...pick(
        this.filterBarInfoComponentOption,
        [
          'columns',
          'id',
          'type',
          'sheet_id',
          'fields_by_source',
          'base_filter',
          ...HELPER_TEXT_ATTRIBUTES,
          ...LABEL_ATTRIBUTES,
        ],
      ),
    };
  }
}
