const TEXT = { type: 'text' };
const FIELD_SELECT = { type: 'field-select' };
const INFO_COMPONENTS = { type: 'info-components' };
const IMAGE_INFO_COMPONENT = { type: 'image-info-component' };
const ACTION_BUTTON = { type: 'action-button' };
const REQUIRED_ACTION_BUTTON = { type: 'action-button', required: true };

const VIEW_EDITABLE_COMPONENT_PATHS = Object.freeze({
  Calendar: ['single_item.main_section.main_content'],
  Cards: ['single_item.main_section.main_content'],
  Content: [],
  Form: ['single_item.main_section.main_content'],
  Kanban: [
    'single_item.main_section.main_content',
    'index.main_section.column_group_by',
  ],
  Matrix: [
    'single_item.main_section.main_content',
    'index.main_section.column_group_by',
    'index.main_section.row_group_by',
  ],
  Report: [],
  Table: ['index.main_section.main_content'],
  Timeline: ['index.main_section.main_content'],
  Tree: ['single_item.main_section.main_content'],
});

const VIEWS_COMPONENTS = Object.freeze({
  Cards: [
    {
      key: 'index',
      icon: 'fa-rectangle-history fa-regular',
      locales: {
        pt: 'Coleção',
        en: 'Collection',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          footer_content: [TEXT, FIELD_SELECT],
          visibility: [TEXT],
        },
        editRecordMode: 'disabled',
        openRecordMode: 'page',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
    {
      key: 'single_item',
      icon: 'fa-rectangle fa-regular',
      locales: {
        pt: 'Entrada',
        en: 'Record',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          cover_image: [FIELD_SELECT, IMAGE_INFO_COMPONENT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          primary_button: [ACTION_BUTTON],
          secondary_button: [ACTION_BUTTON],
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'in_place',
        openRecordMode: 'page',
        submitButtonKey: 'primary_button',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
  ],
  Content: [
    {
      key: 'index',
      icon: 'fa-memo fa-regular',
      locales: {
        pt: 'Conteúdo',
        en: 'Content',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      infoComponentsExhibitConditionsEnabled: false,
    },
  ],
  Form: [
    {
      key: 'index',
      icon: 'fa-rectangle-history fa-regular',
      locales: {
        pt: 'Coleção',
        en: 'Collection',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          footer_content: [TEXT, FIELD_SELECT],
          visibility: [TEXT],
        },
        editRecordMode: 'disabled',
        openRecordMode: 'page',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
    {
      key: 'single_item',
      icon: 'fa-pen-field fa-regular',
      locales: {
        pt: 'Formulário',
        en: 'Form',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          cover_image: [FIELD_SELECT, IMAGE_INFO_COMPONENT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          submit_button: [REQUIRED_ACTION_BUTTON],
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'submit',
        openRecordMode: 'page',
        submitButtonKey: 'submit_button',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
  ],
  Kanban: [
    {
      key: 'index',
      icon: 'fa-square-kanban fa-regular',
      locales: {
        pt: 'Kanban',
        en: 'Kanban',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
    {
      key: 'single_item',
      icon: 'fa-rectangle fa-regular',
      locales: {
        pt: 'Entrada',
        en: 'Record',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          cover_image: [FIELD_SELECT, IMAGE_INFO_COMPONENT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          primary_button: [ACTION_BUTTON],
          secondary_button: [ACTION_BUTTON],
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'in_place',
        openRecordMode: 'dialog',
        submitButtonKey: 'primary_button',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
  ],
  Matrix: [
    {
      key: 'index',
      icon: 'fa-grid-2 fa-regular',
      locales: {
        pt: 'Matriz',
        en: 'Matrix',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'disabled',
        openRecordMode: 'dialog',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
    {
      key: 'single_item',
      icon: 'fa-rectangle fa-regular',
      locales: {
        pt: 'Entrada',
        en: 'Record',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          cover_image: [FIELD_SELECT, IMAGE_INFO_COMPONENT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          primary_button: [ACTION_BUTTON],
          secondary_button: [ACTION_BUTTON],
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'in_place',
        openRecordMode: 'dialog',
        submitButtonKey: 'primary_button',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
  ],
  Calendar: [
    {
      key: 'index',
      icon: 'fa-calendar fa-regular',
      locales: {
        pt: 'Calendário',
        en: 'Calendar',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [FIELD_SELECT],
        },
        editRecordMode: 'disabled',
        openRecordMode: 'page',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
    {
      key: 'single_item',
      icon: 'fa-rectangle fa-regular',
      locales: {
        pt: 'Entrada',
        en: 'Record',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          cover_image: [FIELD_SELECT, IMAGE_INFO_COMPONENT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          primary_button: [ACTION_BUTTON],
          secondary_button: [ACTION_BUTTON],
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'in_place',
        openRecordMode: 'page',
        submitButtonKey: 'primary_button',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
  ],
  Report: [
    {
      key: 'index',
      icon: 'fa-regular fa-file-contract',
      locales: {
        pt: 'Relatório',
        en: 'Report',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      infoComponentsExhibitConditionsEnabled: false,
    },
  ],
  Table: [
    {
      key: 'index',
      icon: 'fa-table fa-regular',
      locales: {
        pt: 'Tabela',
        en: 'Table',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'in_place',
        openRecordMode: 'none',
      },
      infoComponentsExhibitConditionsEnabled: false,
    },
  ],
  Timeline: [
    {
      key: 'single_item',
      icon: 'fa-rectangle fa-regular',
      locales: {
        pt: 'Coleção',
        en: 'Collection',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          cover_image: [FIELD_SELECT, IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
          primary_button: [ACTION_BUTTON],
          secondary_button: [ACTION_BUTTON],
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
        submitButtonKey: 'primary_button',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
    {
      key: 'index',
      icon: 'fa-square-list fa-regular',
      locales: {
        pt: 'Formulário',
        en: 'Form',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT],
          subtitle: [TEXT],
          main_content: INFO_COMPONENTS,
          submit_button: [REQUIRED_ACTION_BUTTON],
          footer_content: [TEXT],
        },
        editRecordMode: 'submit',
        openRecordMode: 'none',
        submitButtonKey: 'submit_button',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
  ],
  Tree: [
    {
      key: 'index',
      icon: 'fa-sitemap fa-regular',
      locales: {
        pt: 'Árvore',
        en: 'Tree',
      },
      header_section: {
        exodiaComponents: {
          title: [TEXT],
          profile_image: [IMAGE_INFO_COMPONENT],
          cover_image: [IMAGE_INFO_COMPONENT],
          main_content: INFO_COMPONENTS,
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          profile_image: [FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'disabled',
        openRecordMode: 'none',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
    {
      key: 'single_item',
      icon: 'fa-rectangle fa-regular',
      locales: {
        pt: 'Entrada',
        en: 'Record',
      },
      main_section: {
        exodiaComponents: {
          title: [TEXT, FIELD_SELECT],
          subtitle: [TEXT, FIELD_SELECT],
          avatar_image: [FIELD_SELECT],
          cover_image: [FIELD_SELECT, IMAGE_INFO_COMPONENT],
          profile_image: [FIELD_SELECT],
          main_content: INFO_COMPONENTS,
          primary_button: [ACTION_BUTTON],
          secondary_button: [ACTION_BUTTON],
          footer_content: [TEXT, FIELD_SELECT],
        },
        editRecordMode: 'in_place',
        openRecordMode: 'dialog',
        submitButtonKey: 'primary_button',
      },
      infoComponentsExhibitConditionsEnabled: true,
    },
  ],
});

const VIEWS_BUILDER_OPTIONS = Object.freeze({
  Cards: {
    availableRedirectStates: [
      'index',
      'single_item',
      'new',
    ],
    availableDefaultStates: [
      'index',
      'single_item',
    ],
    canEditRecordsGroupColumn: false,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: true,
    canGroupDataByColumn: true,
    canGroupDataByRow: false,
    canGroupMainContent: true,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: true,
    canUseFieldInfoComponent: true,
    canUseFilterBarComponent: true,
    editableInfoComponentsKey: 'single_item',
    groupByFieldTypes: [],
    hasGlobalSearch: true,
    liveEdit: true,
    mustGroupDataByColumn: false,
    mustGroupDataByRow: false,
    optionsWithFieldSelect: null,
    permitDelete: true,
    permitInsert: true,
    permitUpdate: true,
    readOnlySelectFields: false,
    showFilterRecords: true,
    showSelectFields: true,
    showSortByRecords: true,
  },
  Content: {
    availableRedirectStates: [
      'index',
    ],
    availableDefaultStates: [
      'index',
    ],
    canEditRecordsGroupColumn: false,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: false,
    canGroupDataByColumn: false,
    canGroupDataByRow: false,
    canGroupMainContent: false,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: false,
    canUseFieldInfoComponent: false,
    canUseFilterBarComponent: false,
    editableInfoComponentsKey: null,
    groupByFieldTypes: [],
    hasGlobalSearch: false,
    liveEdit: true,
    mustGroupDataByColumn: false,
    mustGroupDataByRow: false,
    optionsWithFieldSelect: null,
    permitDelete: false,
    permitInsert: false,
    permitUpdate: false,
    readOnlySelectFields: true,
    showFilterRecords: false,
    showSelectFields: false,
    showSortByRecords: false,
  },
  Form: {
    availableRedirectStates: [
      'index',
      'single_item',
      'new',
    ],
    availableDefaultStates: [
      'index',
      'single_item',
      'new',
    ],
    canEditRecordsGroupColumn: false,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: true,
    canGroupDataByColumn: true,
    canGroupDataByRow: false,
    canGroupMainContent: true,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: true,
    canUseFieldInfoComponent: true,
    canUseFilterBarComponent: true,
    editableInfoComponentsKey: 'single_item',
    groupByFieldTypes: [],
    hasGlobalSearch: true,
    liveEdit: false,
    mustGroupDataByColumn: false,
    mustGroupDataByRow: false,
    optionsWithFieldSelect: null,
    permitDelete: true,
    permitInsert: true,
    permitUpdate: true,
    readOnlySelectFields: true,
    showFilterRecords: true,
    showSelectFields: true,
    showSortByRecords: true,
  },
  Kanban: {
    availableRedirectStates: [
      'index',
      'single_item',
    ],
    availableDefaultStates: [
      'index',
    ],
    canEditRecordsGroupColumn: true,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: true,
    canGroupDataByColumn: true,
    canGroupDataByRow: false,
    canGroupMainContent: true,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: true,
    canUseFieldInfoComponent: true,
    canUseFilterBarComponent: false,
    editableInfoComponentsKey: 'single_item',
    groupByFieldTypes: [
      'Select',
    ],
    hasGlobalSearch: true,
    liveEdit: true,
    mustGroupDataByColumn: true,
    mustGroupDataByRow: false,
    optionsWithFieldSelect: null,
    permitDelete: true,
    permitInsert: true,
    permitUpdate: true,
    readOnlySelectFields: false,
    showFilterRecords: true,
    showSelectFields: true,
    showSortByRecords: true,
  },
  Matrix: {
    availableRedirectStates: [
      'index',
      'single_item',
    ],
    availableDefaultStates: [
      'index',
    ],
    canEditRecordsGroupColumn: true,
    canEditRecordsGroupRow: true,
    canFilterDataByColumn: true,
    canGroupDataByColumn: true,
    canGroupDataByRow: true,
    canGroupMainContent: true,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: true,
    canUseFieldInfoComponent: true,
    canUseFilterBarComponent: false,
    editableInfoComponentsKey: 'single_item',
    groupByFieldTypes: [
      'Select',
    ],
    hasGlobalSearch: false,
    liveEdit: true,
    mustGroupDataByColumn: true,
    mustGroupDataByRow: true,
    optionsWithFieldSelect: null,
    permitDelete: true,
    permitInsert: true,
    permitUpdate: true,
    readOnlySelectFields: false,
    showFilterRecords: true,
    showSelectFields: true,
    showSortByRecords: true,
  },
  Calendar: {
    availableRedirectStates: [
      'index',
      'single_item',
    ],
    availableDefaultStates: [
      'index',
    ],
    canEditRecordsGroupColumn: false,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: true,
    canGroupDataByColumn: false,
    canGroupDataByRow: false,
    canGroupMainContent: true,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: true,
    canUseFieldInfoComponent: true,
    canUseFilterBarComponent: false,
    editableInfoComponentsKey: 'single_item',
    groupByFieldTypes: [],
    hasGlobalSearch: false,
    optionsWithFieldSelect: [
      {
        key: 'start_date',
        allowedFieldTypes: [
          'Date',
          'DateTime',
        ],
      },
      {
        key: 'end_date',
        allowedFieldTypes: [
          'Date',
          'DateTime',
        ],
      },
      {
        key: 'color',
        allowedFieldTypes: [
          'Link',
          'Select',
          'MultipleSelect',
        ],
      },
    ],
    liveEdit: true,
    mustGroupDataByColumn: false,
    mustGroupDataByRow: false,
    permitDelete: true,
    permitInsert: true,
    permitUpdate: true,
    readOnlySelectFields: false,
    showFilterRecords: true,
    showSelectFields: true,
    showSortByRecords: true,
  },
  Report: {
    availableRedirectStates: [
      'index',
    ],
    availableDefaultStates: [
      'index',
    ],
    canEditRecordsGroupColumn: false,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: true,
    canGroupDataByColumn: true,
    canGroupDataByRow: false,
    canGroupMainContent: false,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: true,
    canUseFieldInfoComponent: false,
    canUseFilterBarComponent: false,
    editableInfoComponentsKey: null,
    groupByFieldTypes: [],
    hasGlobalSearch: false,
    liveEdit: true,
    mustGroupDataByColumn: false,
    mustGroupDataByRow: false,
    optionsWithFieldSelect: null,
    permitDelete: false,
    permitInsert: false,
    permitUpdate: false,
    readOnlySelectFields: true,
    showFilterRecords: true,
    showSelectFields: false,
    showSortByRecords: false,
  },
  Table: {
    availableRedirectStates: [
      'index',
    ],
    availableDefaultStates: [
      'index',
    ],
    canEditRecordsGroupColumn: false,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: true,
    canGroupDataByColumn: false,
    canGroupDataByRow: false,
    canGroupMainContent: true,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: false,
    canUseFieldInfoComponent: true,
    canUseFilterBarComponent: false,
    editableInfoComponentsKey: 'index',
    groupByFieldTypes: [],
    hasGlobalSearch: false,
    liveEdit: true,
    mustGroupDataByColumn: false,
    mustGroupDataByRow: false,
    optionsWithFieldSelect: null,
    permitDelete: true,
    permitInsert: true,
    permitUpdate: true,
    readOnlySelectFields: false,
    showFilterRecords: true,
    showSelectFields: true,
    showSortByRecords: true,
  },
  Timeline: {
    availableRedirectStates: [
      'index',
    ],
    availableDefaultStates: [
      'index',
    ],
    canEditRecordsGroupColumn: false,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: true,
    canGroupDataByColumn: false,
    canGroupDataByRow: false,
    canGroupMainContent: false,
    canSelectRecursiveField: false,
    canUseChartInfoComponent: false,
    canUseFieldInfoComponent: true,
    canUseFilterBarComponent: true,
    editableInfoComponentsKey: 'index',
    groupByFieldTypes: [],
    hasGlobalSearch: false,
    liveEdit: false,
    mustGroupDataByColumn: false,
    mustGroupDataByRow: false,
    optionsWithFieldSelect: null,
    permitDelete: true,
    permitInsert: true,
    permitUpdate: true,
    readOnlySelectFields: true,
    showFilterRecords: true,
    showSelectFields: true,
    showSortByRecords: true,
  },
  Tree: {
    availableRedirectStates: [
      'index',
      'single_item',
    ],
    availableDefaultStates: [
      'index',
    ],
    canEditRecordsGroupColumn: false,
    canEditRecordsGroupRow: false,
    canFilterDataByColumn: true,
    canGroupDataByColumn: false,
    canGroupDataByRow: false,
    canGroupMainContent: false,
    canSelectRecursiveField: true,
    canUseChartInfoComponent: true,
    canUseFieldInfoComponent: true,
    canUseFilterBarComponent: false,
    editableInfoComponentsKey: 'single_item',
    groupByFieldTypes: [],
    hasGlobalSearch: false,
    liveEdit: true,
    mustGroupDataByColumn: false,
    mustGroupDataByRow: false,
    optionsWithFieldSelect: null,
    permitDelete: true,
    permitInsert: true,
    permitUpdate: true,
    readOnlySelectFields: false,
    showFilterRecords: true,
    showSelectFields: true,
    showSortByRecords: false,
  },
});

const VIEWS_BASE = Object.freeze({
  Cards: {
    position: 2,
    canBePublic: true,
    icon: 'fa-rectangle-history fa-regular',
    rawIcon: 'rectangle-history',
    locales: {
      pt: 'Cards',
      en: 'Cards',
    },
    editInPlace: true,
  },
  Content: {
    position: 5,
    canBePublic: true,
    icon: 'fa-memo fa-regular',
    rawIcon: 'memo',
    locales: {
      pt: 'Conteúdo',
      en: 'Content',
    },
    editInPlace: false,
  },
  Form: {
    position: 3,
    canBePublic: true,
    icon: 'fa-pen-field fa-regular',
    rawIcon: 'pen-field',
    locales: {
      pt: 'Formulário',
      en: 'Form',
    },
    editInPlace: false,
  },
  Kanban: {
    position: 6,
    canBePublic: true,
    icon: 'fa-square-kanban fa-regular',
    rawIcon: 'square-kanban',
    locales: {
      pt: 'Kanban',
      en: 'Kanban',
    },
    editInPlace: true,
  },
  Matrix: {
    position: 7,
    canBePublic: true,
    icon: 'fa-grid-2 fa-regular',
    rawIcon: 'grid-2',
    locales: {
      pt: 'Matriz',
      en: 'Matrix',
    },
    editInPlace: true,
  },
  Calendar: {
    position: 9,
    canBePublic: true,
    icon: 'fa-calendar fa-regular',
    rawIcon: 'calendar',
    locales: {
      pt: 'Calendário',
      en: 'Calendar',
    },
    editInPlace: false,
  },
  Report: {
    position: 8,
    canBePublic: true,
    icon: 'fa-file-contract fa-regular',
    rawIcon: 'file-contract',
    locales: {
      pt: 'Relatório',
      en: 'Report',
    },
    editInPlace: false,
  },
  Table: {
    position: 1,
    canBePublic: true,
    icon: 'fa-table fa-regular',
    rawIcon: 'table',
    locales: {
      pt: 'Tabela',
      en: 'Table',
    },
    editInPlace: true,
  },
  Timeline: {
    position: 4,
    canBePublic: true,
    icon: 'fa-timeline fa-regular',
    rawIcon: 'timeline',
    locales: {
      pt: 'Feed',
      en: 'Feed',
    },
    editInPlace: true,
  },
  Tree: {
    position: 10,
    canBePublic: true,
    icon: 'fa-sitemap fa-regular',
    rawIcon: 'sitemap',
    locales: {
      pt: 'Árvore',
      en: 'Tree',
    },
    editInPlace: false,
  },
});

export default Object.freeze(Object.fromEntries(Object.entries(VIEWS_BASE).map(([key, value]) => [
  key,
  Object.freeze({
    ...value,
    builderOptions: VIEWS_BUILDER_OPTIONS[key],
    components: VIEWS_COMPONENTS[key],
    editableComponentPaths: VIEW_EDITABLE_COMPONENT_PATHS[key],
  }),
])));

// const COMPONENT_LABEL = {
//   'info-components': 'MainContent',
//   'field-select': 'Field',
//   'action-button': 'Action',
//   'image-info-component': 'Image',
//   text: 'Text',
// };

// function prettyPrintViewComponents(viewComponents) {
//   const SPACER = '  ';
//   let output = '';

//   function formatComponent(component) {
//     let value = '';

//     if (Array.isArray(component)) {
//       value = component.map(formatComponent).join(', ');
//     } else {
//       value = COMPONENT_LABEL[component.type];
//     }

//     if (component.required) {
//       value += ' (required)';
//     }

//     return value;
//   }

//   Object.keys(viewComponents).forEach((viewName) => {
//     output += `${viewName}\n`;

//     viewComponents[viewName].forEach((component) => {
//       output += `${SPACER.repeat(1)}Component ${component.key}\n`;

//       ['header_section', 'main_section'].forEach((section) => {
//         output += `${SPACER.repeat(2)}Section ${section}:\n${SPACER.repeat(3)}Exodia Components:\n`;
//         const { exodiaComponents = {} } = component[section] || {};

//         Object.entries(exodiaComponents).forEach(([compName, compValue]) => {
//           const compValueStr = formatComponent(compValue);
//           output += `${SPACER.repeat(4)}${compName.charAt(0).toUpperCase() + compName.slice(1)}: ${compValueStr}\n`;
//         });
//       });
//     });

//     output += '--------------------------------------\n';
//   });

//   // eslint-disable-next-line no-console
//   console.log(output);
// }

// // Example call
// prettyPrintViewComponents(VIEWS_COMPONENTS);
